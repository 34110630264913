<template>
  <div class="wrapper">
    <div class="left-panel scroller">
      <v-row justify="center">
        <v-col cols="11" class="mt-5">

          <v-row justify="center" v-if="$vuetify.breakpoint.smAndDown" class="ma-2">
            <img
              src="@/assets/images/landing/signUp/image0.png"
              class="graph-img-movil"
            />
          </v-row>
          
          <v-row justify="center">
            <span class="wc-h5 ma-4 text-center">
              ¡Comencemos a trabajar juntos!
            </span>
          </v-row>

          <v-row>
            <span class="wc-body-2 mx-10 mb-6 text-center">
              Estás a unos pocos pasos de crear tu cuenta y librarte del trabajo
              repetitivo en tu organización
            </span>
          </v-row>

          <v-form ref="accountDataForm">
            <v-row justify="center">
              <v-col cols="12" sm="10" class="pt-1 pb-0">
                <span class="wc-caption-1 font-weight-bold">Nombre(s)</span>
                <v-text-field
                  v-model="computedAccountData.name"
                  rounded
                  outlined
                  dense
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="10" class="pt-1 pb-0">
                <span class="wc-caption-1 font-weight-bold">Apellido(s)</span>
                <v-text-field
                  v-model="computedAccountData.lastName"
                  rounded
                  outlined
                  dense
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="10" class="pt-1 pb-0">
                <span class="wc-caption-1 font-weight-bold"
                  >Nombre de la Organización</span
                >
                <v-text-field
                  v-model="computedAccountData.company"
                  rounded
                  outlined
                  dense
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="10" class="pt-1 pb-0">
                <span class="wc-caption-1 font-weight-bold"
                  >Correo Electrónico</span
                >
                <v-text-field
                  v-model="computedAccountData.email"
                  required
                  rounded
                  outlined
                  dense
                  :rules="emailRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="10" class="pt-1 pb-0">
                <span class="wc-caption-1 font-weight-bold">Contraseña</span>
                <v-text-field
                  v-model="computedAccountData.password"
                  required
                  rounded
                  outlined
                  dense
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                >
                  <template v-slot:append>
                    <v-icon
                      :color="showPassword ? '#1b273e' : ''"
                      @click="showPassword = !showPassword"
                    >
                      {{ showPassword ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="10" class="pt-1 pb-0">
                <span class="wc-caption-1 font-weight-bold"
                  >Confirmar Contraseña</span
                >
                <v-text-field
                  v-model="computedAccountData.confirmPassword"
                  required
                  rounded
                  outlined
                  dense
                  :type="showConfirmPassword ? 'text' : 'password'"
                  :rules="confirmPasswordRules"
                >
                  <template v-slot:append>
                    <v-icon
                      :color="showConfirmPassword ? '#1b273e' : ''"
                      @click="showConfirmPassword = !showConfirmPassword"
                    >
                      {{ showConfirmPassword ? "mdi-eye" : "mdi-eye-off" }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>

          <v-row justify="center" class="ma-4 mt-10">
            <v-btn
              rounded
              color="#1b273e"
              class="white--text"
              @click="nextStep()"
            >
              CONTINUAR
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="right-panel" v-if="!$vuetify.breakpoint.smAndDown">
      <img
        src="@/assets/images/landing/signUp/WeChamberGraphs.png"
        class="graph-img"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUp",

  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
    };
  },

  computed: {
    computedAccountData: {
      get() {
        return this.accountData;
      },
      set(value) {
        this.$emit("setAccountData", value);
      },
    },
    passwordRules() {
      return [
        (v) => !!v || "La contraseña es requerida",
        (v) =>
          v.length >= 8 || "La contraseña debe tener al menos 8 caracteres",
        (v) =>
          /[A-Z]/.test(v) ||
          "La contraseña debe tener al menos una letra mayúscula",
        (v) =>
          /[a-z]/.test(v) ||
          "La contraseña debe tener al menos una letra minúscula",
        (v) =>
          /[^A-Za-z0-9]/.test(v) ||
          "La contraseña debe tener al menos un caracter especial",
      ];
    },

    emailRules() {
      return [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El correo electrónico debe ser válido",
      ];
    },

    confirmPasswordRules() {
      return [
        (v) => !!v || "La confirmación de contraseña es requerida",
        (v) =>
          v === this.computedAccountData.password ||
          "Las contraseñas no coinciden",
      ];
    },

    requiredRules() {
      return [(v) => !!v || "Este campo es requerido"];
    },
  },

  methods: {
    nextStep() {
      if (this.$refs.accountDataForm.validate()) {
        this.$emit("nextStep");
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh !important;
  width: 100vw !important;
  display: flex !important;
}

.left-panel {
  background-color: #fff !important;
  height: 100vh !important;
  width: 40vw !important;
  display: flex !important;
  flex-direction: column !important;
  z-index: 0 !important;
}

@media screen and (max-width: 960px) {
  .left-panel {
    width: 100vw !important;
  }

  .right-panel {
    display: none !important;
  }
}

.right-panel {
  background-color: #ebebeb !important;
  height: 100vh !important;
  width: 60vw !important;
  display: flex !important;
  flex-direction: column !important;
  z-index: 1 !important;
}

.graph-img {
  position: absolute !important;
  height: 65vh !important;
  width: 65vw !important;
  right: -20vw !important;
  bottom: -6vh !important;
  border-radius: 50px !important;
  border: 10px solid #000 !important;
}

.graph-img-movil {
  width: 40vw !important;
  height: 40vw !important;
  min-width: 300px !important;
  min-height: 300px !important;
}

.orange-circle {
  position: absolute !important;
  height: 500px !important;
  width: 500px !important;
  left: -250px !important;
  top: -250px !important;
  opacity: 0.8 !important;
  z-index: -1 !important;
}

.blue-circle {
  position: absolute !important;
  height: 500px !important;
  width: 500px !important;
  left: 30vw !important;
  bottom: -50px !important;
  opacity: 0.8 !important;
  z-index: -1 !important;
}

.scroller {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scroller::-webkit-scrollbar {
  display: none;
}
</style>