<template>
  <div>
    <AccountDataStep
      v-if="step == 1"
      :accountData="accountData"
      @setAccountData="setAccountData()"
      @nextStep="nextStep()"
    ></AccountDataStep>

    <AccountSettingsStep
      v-if="step == 2"
      :accountSettings="accountSettings"
      @setAccountSettings="setAccountSettings()"
      @nextStep="nextStep()"
      @previousStep="previousStep()"
    ></AccountSettingsStep>

    <PaymentSettingsStep
      v-if="step == 3 && accountSettings.hierarchyType != 'ONE_LEVEL'"
      :paymentSettings="paymentSettings"
      :step="step"
      @setPaymentSettings="setPaymentSettings()"
      @nextStep="nextStep()"
      @previousStep="previousStep()"
    ></PaymentSettingsStep>

    <ReviewStep
      v-if="
        (step == 3 && accountSettings.hierarchyType == 'ONE_LEVEL') || 
        (step == 4 && accountSettings.hierarchyType != 'ONE_LEVEL')
      "
      :paymentSettings="paymentSettings"
      :accountData="accountData"
      :accountSettings="accountSettings"
      :step="step"
      :loading="loading"
      @nextStep="createAccount()"
      @previousStep="previousStep()"
    ></ReviewStep>

    <WelcomeStep
      v-if="
        (step == 4 && accountSettings.hierarchyType == 'ONE_LEVEL') || step == 5
      "
    ></WelcomeStep>
  </div>
</template>

<script>
import AccountDataStep from "./Steps/AccountDataStep.vue";
import AccountSettingsStep from "./Steps/AccountSettingsStep.vue";
import PaymentSettingsStep from "./Steps/PaymentSettingsStep.vue";
import ReviewStep from "./Steps/ReviewStep.vue";
import WelcomeStep from "./Steps/WelcomeStep.vue";
import { mapMutations } from "vuex";

export default {
  name: "SignUp",

  components: {
    AccountDataStep,
    AccountSettingsStep,
    PaymentSettingsStep,
    ReviewStep,
    WelcomeStep,
  },

  data() {
    return {
      step: 1,
      loading: false,
      accountData: {
        name: "",
        lastName: "",
        company: "",
        email: "",
        password: "",
        confirmPassword: "",
      },

      accountSettings: {
        role: "ORGANIZATION",
        hierarchyType: "ONE_LEVEL",
      },

      paymentSettings: {
        paymentType: "NATIONAL",
      },
    };
  },

  computed: {},

  methods: {
    ...mapMutations("notification", ["show"]),
    async createAccount() {
      this.loading = true;
      const response = await this.axios({
        method: "POST",
        url: "users/create",
        data: {
          ...this.accountData,
          ...this.accountSettings,
          ...this.paymentSettings,
        },
      });

      if (response.status == 200) {
        this.step++;
        this.loading = false;
      } else {
        this.show({
          text: "Hubo un problema al crear la cuenta",
          color: "error",
        });
      }
    },

    nextStep() {
      this.step++;
    },

    previousStep() {
      this.step--;
    },

    setAccountData(data) {
      this.accountData = data;
    },

    setAccountSettings(data) {
      this.accountSettings = data;
    },

    setPaymentSettings(data) {
      this.paymentSettings = data;
    },
  },
};
</script>

<style scoped>
</style>