<template>
  <div class="wrapper">
    <div class="left-panel scroller">
      <v-row justify="center">
        <v-col cols="11" class="mt-5">
          <v-row class="ma-2 mt-5" v-if="$vuetify.breakpoint.smAndDown">
            <span class="wc-body-1 font-weight-bold">Paso 2.</span>
          </v-row>
          <v-row class="ma-2" v-if="$vuetify.breakpoint.smAndDown">
            <span class="wc-h5 font-weight-bold"
              >Configura el esquema de pagos de cuotas de tu organización</span
            >
          </v-row>
          <v-row class="ma-2" v-if="$vuetify.breakpoint.smAndDown">
            <span class="wc-body-1">
              Elige quién debe captar los pagos de cuotas de tus membresías.
            </span>
          </v-row>

          <v-row
            justify="center"
            v-if="$vuetify.breakpoint.smAndDown"
            class="ma-2"
          >
            <img
              src="@/assets/images/landing/signUp/image2.png"
              class="graph-img-movil"
            />
          </v-row>

          <v-row class="ma-2">
            <span class="wc-h6 ma-2 text-start">
              ¿Cual es el esquema de pago de membresías de tu organización?
            </span>
          </v-row>

          <v-form ref="paymentSettingsForm">
            <v-radio-group v-model="paymentSettings.paymentType">
              <v-row justify="center">
                <v-sheet
                  color="white"
                  rounded="lg"
                  class="pa-4 signUp-sheet d-flex flex-column"
                >
                  <!-- Posicionamos el v-radio arriba a la derecha con la clase "align-end" -->
                  <v-radio
                    class="sheet-radio align-end"
                    value="LOCAL"
                  ></v-radio>

                  <v-row class="ma-0">
                    <!-- Posicionamos el texto a la izquierda arriba con la clase "align-start" -->
                    <span class="wc-body-1 font-weight-bold align-start"
                      >Pago Local</span
                    >
                  </v-row>

                  <v-row class="ma-0 mt-2">
                    <span class="wc-body-2">
                      Cada delegación o capítulo local recibe los pagos de
                      cuotas de sus respectivos miembros.
                    </span>
                  </v-row>
                </v-sheet>
              </v-row>

              <v-row justify="center" class="mt-8">
                <v-sheet
                  color="white"
                  rounded="lg"
                  class="pa-4 signUp-sheet d-flex flex-column"
                >
                  <!-- Posicionamos el v-radio arriba a la derecha con la clase "align-end" -->
                  <v-radio
                    class="sheet-radio align-end"
                    value="NATIONAL"
                  ></v-radio>

                  <v-row class="ma-0">
                    <!-- Posicionamos el texto a la izquierda arriba con la clase "align-start" -->
                    <span class="wc-body-1 font-weight-bold align-start"
                      >Pago Central</span
                    >
                  </v-row>

                  <v-row class="ma-0 mt-2">
                    <span class="wc-body-2">
                      La matriz o sede principal recibe los pagos de cuotas de
                      todos los miembros.
                    </span>
                  </v-row>
                </v-sheet>
              </v-row>

              <v-row justify="center" class="mt-8">
                <v-sheet
                  color="white"
                  rounded="lg"
                  class="pa-4 signUp-sheet d-flex flex-column"
                >
                  <!-- Posicionamos el v-radio arriba a la derecha con la clase "align-end" -->
                  <v-radio class="sheet-radio align-end" value="BOTH"></v-radio>

                  <v-row class="ma-0">
                    <!-- Posicionamos el texto a la izquierda arriba con la clase "align-start" -->
                    <span class="wc-body-1 font-weight-bold align-start"
                      >Pago Doble</span
                    >
                  </v-row>

                  <v-row class="ma-0 mt-2">
                    <span class="wc-body-2">
                      El miembro debe cubrir dos pagos para considerarse miembro
                      activo: uno a su delegación o capítulo local y otro más a
                      la matriz o sede principal. Cada monto es totalmente
                      configurable.
                    </span>
                  </v-row>
                </v-sheet>
              </v-row>
            </v-radio-group>
          </v-form>

          <v-row justify="center" class="ma-4 mt-10">
            <v-btn
              rounded
              outlined
              color="white"
              class="back-button mx-2"
              @click="previousStep()"
            >
              REGRESAR
            </v-btn>
            <v-btn
              rounded
              color="#1b273e"
              class="white--text mx-2"
              @click="nextStep()"
            >
              CONTINUAR
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="right-panel" v-if="!$vuetify.breakpoint.smAndDown">
      <v-row justify="center">
        <v-col cols="8">
          <v-row class="mt-10">
            <span class="wc-body-1 font-weight-bold">Paso {{ step }}.</span>
          </v-row>
          <v-row class="mt-4">
            <span class="wc-h5 font-weight-bold"
              >Configura tu Modelo de Pagos</span
            >
          </v-row>
          <v-row class="mt-4">
            <span class="wc-body-1">
              Elige a quien quieres que se le acredite el dinero de los pagos de
              membresías
            </span>
          </v-row>

          <v-row justify="center">
            <img
              src="@/assets/images/landing/signUp/image2.png"
              class="graph-img"
            />
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUp",

  props: {
    paymentSettings: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },

  computed: {
    computedPaymentSettings: {
      get() {
        return this.paymentSettings;
      },
      set(value) {
        this.$emit("setPaymentSettings", value);
      },
    },
  },

  methods: {
    nextStep() {
      this.$emit("nextStep");
    },

    previousStep() {
      this.$emit("previousStep");
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh !important;
  width: 100vw !important;
  display: flex !important;
}

.left-panel {
  background-color: #fff !important;
  height: 100vh !important;
  width: 40vw !important;
  display: flex !important;
  flex-direction: column !important;
  z-index: 0 !important;
}

.right-panel {
  background-color: #ebebeb !important;
  height: 100vh !important;
  width: 60vw !important;
  display: flex !important;
  flex-direction: column !important;
  z-index: 1 !important;
}

.graph-img {
  height: 65vh !important;
  width: 65vh !important;
}

.graph-img-movil {
  width: 40vw !important;
  height: 40vw !important;
  min-width: 300px !important;
  min-height: 300px !important;
}

.orange-circle {
  position: absolute !important;
  height: 400px !important;
  width: 400px !important;
  left: -350px !important;
  bottom: 0px !important;
  opacity: 0.8 !important;
  z-index: -1 !important;
}

.blue-circle {
  position: absolute !important;
  height: 400px !important;
  width: 400px !important;
  left: 25vw !important;
  top: 50px !important;
  opacity: 0.8 !important;
  z-index: -1 !important;
}

.signUp-sheet {
  width: 90% !important;
  border: 1px solid #d1d1d1 !important;
}

.sheet-radio {
  margin-bottom: -20px !important;
  margin-left: auto !important;
}

.scroller {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scroller::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 960px) {
  .left-panel {
    width: 100vw !important;
  }

  .right-panel {
    display: none !important;
  }
}

.back-button {
  color: #1b273e !important;
}
</style>