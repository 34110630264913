<template>
  <div class="wrapper">
    <div class="left-panel scroller">
      <v-row justify="center">
        <v-col cols="11" class="mt-5">
          <v-row
            justify="center"
            v-if="$vuetify.breakpoint.smAndDown"
            class="ma-2"
          >
            <img
              src="@/assets/images/weChamber/logo-WC-black.png"
              class="graph-img-movil"
            />
          </v-row>

          <v-row class="ma-2 mb-6" justify="center">
            <span class="wc-h5 text-center"> ¡Bienvenido a WeChamber! </span>
          </v-row>

          <v-row class="ma-2 mb-6">
            <span class="wc-body-1 text-justify">
              Felicidades, has creado exitosamente tu cuenta en WeChamber. Estás
              listo para automatizar y hacer crecer tu organización
            </span>
          </v-row>

          <v-row class="ma-2 mb-6" justify="center">
            <v-col cols="6" sm="4">
              <v-img
                src="https://creazilla-store.fra1.digitaloceanspaces.com/icons/3430831/checked-icon-md.png"
              >
              </v-img>
            </v-col>
          </v-row>

          <v-row class="ma-2 mb-6">
            <span class="wc-body-1 text-justify">
              A continuacion, recibirás un correo electrónico donde deberás
              validar tu cuenta y estarás listo para
              <router-link to="/login" class="font-weight-bold">iniciar sesión</router-link>
              en WeChamber.
            </span>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="right-panel" v-if="!$vuetify.breakpoint.smAndDown">
      <v-row justify="center" class="h-100" align="center">
        <v-col cols="8">
          <v-row justify="center" class="ma-0">
            <img
              src="@/assets/images/weChamber/logo-WC-black.png"
              class="graph-img"
            />
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUp",

  data() {
    return {
      termsAndConditions: false,
      reviewChecked: false,
    };
  },

  methods: {
    nextStep() {
      if (this.$refs.reviewForm.validate()) {
        this.$emit("nextStep");
      }
    },

    previousStep() {
      this.$emit("previousStep");
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh !important;
  width: 100vw !important;
  display: flex !important;
}

.left-panel {
  background-color: #fff !important;
  height: 100vh !important;
  width: 40vw !important;
  display: flex !important;
  flex-direction: column !important;
  z-index: 0 !important;
}

.right-panel {
  background-color: #ebebeb !important;
  height: 100vh !important;
  width: 60vw !important;
  display: flex !important;
  flex-direction: column !important;
  z-index: 1 !important;
}

.graph-img {
  height: 40vh !important;
  max-width: 50vw !important;
  aspect-ratio: auto !important;
}

.graph-img-movil {
  width: 60vw !important;
  margin-bottom: 30px !important;
}

.orange-circle {
  position: absolute !important;
  height: 400px !important;
  width: 400px !important;
  left: -350px !important;
  bottom: 0px !important;
  opacity: 0.8 !important;
  z-index: -1 !important;
}

.blue-circle {
  position: absolute !important;
  height: 400px !important;
  width: 400px !important;
  left: 25vw !important;
  top: 50px !important;
  opacity: 0.8 !important;
  z-index: -1 !important;
}

.signUp-sheet {
  width: 90% !important;
  border: 1px solid #d1d1d1 !important;
}

.sheet-radio {
  margin-bottom: -20px !important;
  margin-left: auto !important;
}

.scroller {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scroller::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 960px) {
  .left-panel {
    width: 100vw !important;
  }

  .right-panel {
    display: none !important;
  }
}

.back-button {
  color: #1b273e !important;
}
</style>