<template>
  <div class="wrapper">
    <div class="left-panel scroller">
      <v-row justify="center">
        <v-col cols="11" class="mt-5">
          <v-row class="ma-2 mt-5" v-if="$vuetify.breakpoint.smAndDown">
            <span class="wc-body-1 font-weight-bold">Paso 2.</span>
          </v-row>
          <v-row class="ma-2" v-if="$vuetify.breakpoint.smAndDown">
            <span class="wc-h5 font-weight-bold">Configura tu Cuenta</span>
          </v-row>
          <v-row class="ma-2" v-if="$vuetify.breakpoint.smAndDown">
            <span class="wc-body-1">
              Elige los módulos que deseas activar, no te preocupes si más
              adelante deseas hacer cambios, solo deberás ponerte en contacto
              con el equipo de WeChamber
            </span>
          </v-row>

          <v-row
            justify="center"
            v-if="$vuetify.breakpoint.smAndDown"
            class="ma-2"
          >
            <img
              src="@/assets/images/landing/signUp/image1.png"
              class="graph-img-movil"
            />
          </v-row>

          <v-row class="ma-2">
            <span class="wc-h6 ma-2 text-start">
              ¿Para qué vas a utilizar WeChamber?
            </span>
          </v-row>

          <v-form ref="accountSettingsForm">
            <v-radio-group v-model="accountSettings.role">
              <v-row justify="center">
                <v-sheet
                  color="white"
                  rounded="lg"
                  class="pa-4 signUp-sheet d-flex flex-column"
                >
                  <!-- Posicionamos el v-radio arriba a la derecha con la clase "align-end" -->
                  <v-radio
                    class="sheet-radio align-end"
                    value="ORGANIZATION"
                  ></v-radio>

                  <v-row class="ma-0">
                    <!-- Posicionamos el texto a la izquierda arriba con la clase "align-start" -->
                    <span class="wc-body-1 font-weight-bold align-start"
                      >Solo eventos</span
                    >
                  </v-row>

                  <v-row class="ma-0 mt-2">
                    <span class="wc-caption-2">
                      Deseo administrar cursos, talleres, congresos, foros,
                      seminarios o cualquier tipo de evento.
                    </span>
                  </v-row>
                </v-sheet>
              </v-row>

              <v-row justify="center" class="mt-8">
                <v-sheet
                  color="white"
                  rounded="lg"
                  class="pa-4 signUp-sheet d-flex flex-column"
                >
                  <!-- Posicionamos el v-radio arriba a la derecha con la clase "align-end" -->
                  <v-radio
                    class="sheet-radio align-end"
                    value="HEAD_OFFICE"
                  ></v-radio>

                  <v-row class="ma-0">
                    <!-- Posicionamos el texto a la izquierda arriba con la clase "align-start" -->
                    <span class="wc-body-1 font-weight-bold align-start"
                      >Membresías y Eventos</span
                    >
                  </v-row>

                  <v-row class="ma-0 mt-2">
                    <span class="wc-caption-2">
                      Deseo administrar los miembros de mi organización, tener
                      un directorio virtual, ofrecerles credenciales virtuales,
                      recibir y monitorear pagos de cuotas, gestionar beneficios
                      y descuentos, llevar estadísticas y más. Además de
                      administrar los cursos y eventos de mi organización. Todo
                      en uno.
                    </span>
                  </v-row>
                </v-sheet>
              </v-row>
            </v-radio-group>

            <v-row
              class="ma-2 mt-6"
              v-if="accountSettings.role == 'HEAD_OFFICE'"
            >
              <span class="wc-h6 ma-2 text-start">
                Selecciona el tipo de estructura que tiene tu organización
              </span>
            </v-row>

            <v-radio-group
              v-model="accountSettings.hierarchyType"
              v-if="accountSettings.role == 'HEAD_OFFICE'"
            >
              <v-row justify="center">
                <v-sheet
                  color="white"
                  rounded="lg"
                  class="pa-5 signUp-sheet d-flex flex-column"
                >
                  <!-- Posicionamos el v-radio arriba a la derecha con la clase "align-end" -->
                  <v-radio
                    class="sheet-radio align-end"
                    value="ONE_LEVEL"
                  ></v-radio>

                  <v-row class="ma-0">
                    <!-- Posicionamos el texto a la izquierda arriba con la clase "align-start" -->
                    <span class="wc-body-1 font-weight-bold align-start">
                      Estructura Simple
                    </span>
                  </v-row>

                  <v-row class="ma-0 mt-2">
                    <span class="wc-caption-2">
                      Organización simple sin delegaciones o capítulos locales
                      debajo de ella. Es posible que tu organización sea un
                      capítulo o delegación local que pertenezca a una red
                      nacional o regional, no te preocupes, más adelante
                      conforme se vayan sumando otros capítulos o delegaciones,
                      se podrá realizar una migración especial para incluir a
                      toda la red en una misma cuenta con estructura nacional o
                      regional. Por ahora deberás elegir esta opción.
                    </span>
                  </v-row>
                </v-sheet>
              </v-row>

              <v-row justify="center" class="mt-8">
                <v-sheet
                  color="white"
                  rounded="lg"
                  class="pa-5 signUp-sheet d-flex flex-column"
                >
                  <!-- Posicionamos el v-radio arriba a la derecha con la clase "align-end" -->
                  <v-radio
                    class="sheet-radio align-end"
                    value="TWO_LEVELS"
                  ></v-radio>

                  <v-row class="ma-0">
                    <!-- Posicionamos el texto a la izquierda arriba con la clase "align-start" -->
                    <span class="wc-body-1 font-weight-bold align-start">
                      Estructura Nacional - Regional
                    </span>
                  </v-row>

                  <v-row class="ma-0 mt-2">
                    <span class="wc-caption-2">
                      Mi organización cuenta con una matriz o sede principal y
                      delegaciones o capítulos locales debajo de ella.
                    </span>
                  </v-row>
                </v-sheet>
              </v-row>
            </v-radio-group>
          </v-form>

          <v-row justify="center" class="ma-4 mt-10">
            <v-btn
              rounded
              outlined
              color="white"
              class="back-button mx-2"
              @click="previousStep()"
            >
              REGRESAR
            </v-btn>
            <v-btn
              rounded
              color="#1b273e"
              class="white--text mx-2"
              @click="nextStep()"
            >
              CONTINUAR
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="right-panel" v-if="!$vuetify.breakpoint.smAndDown">
      <v-row justify="center">
        <v-col cols="8">
          <v-row class="mt-10">
            <span class="wc-body-1 font-weight-bold">Paso 2.</span>
          </v-row>
          <v-row class="mt-4">
            <span class="wc-h5 font-weight-bold">Configura tu Cuenta</span>
          </v-row>
          <v-row class="mt-4">
            <span class="wc-body-1">
              Elige los módulos que deseas activar, no te preocupes si más
              adelante deseas hacer cambios, solo deberás ponerte en contacto
              con el equipo de WeChamber
            </span>
          </v-row>

          <v-row justify="center">
            <img
              src="@/assets/images/landing/signUp/image1.png"
              class="graph-img"
            />
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUp",

  props: {
    accountSettings: {
      type: Object,
      required: true,
    },
  },

  computed: {
    computedAccountSettings: {
      get() {
        return this.accountSettings;
      },
      set(value) {
        this.$emit("setAccountSettings", value);
      },
    },
  },

  methods: {
    nextStep() {
      this.$emit("nextStep");
    },

    previousStep() {
      this.$emit("previousStep");
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh !important;
  width: 100vw !important;
  display: flex !important;
}

.left-panel {
  background-color: #fff !important;
  height: 100vh !important;
  width: 40vw !important;
  display: flex !important;
  flex-direction: column !important;
  z-index: 0 !important;
}

.right-panel {
  background-color: #ebebeb !important;
  height: 100vh !important;
  width: 60vw !important;
  display: flex !important;
  flex-direction: column !important;
  z-index: 1 !important;
}

.graph-img {
  height: 65vh !important;
  width: 65vh !important;
}

.graph-img-movil {
  width: 40vw !important;
  height: 40vw !important;
  min-width: 300px !important;
  min-height: 300px !important;
}

.orange-circle {
  position: absolute !important;
  height: 400px !important;
  width: 400px !important;
  left: -350px !important;
  bottom: 0px !important;
  opacity: 0.8 !important;
  z-index: -1 !important;
}

.blue-circle {
  position: absolute !important;
  height: 400px !important;
  width: 400px !important;
  left: 25vw !important;
  top: 50px !important;
  opacity: 0.8 !important;
  z-index: -1 !important;
}

.signUp-sheet {
  width: 90% !important;
  border: 1px solid #d1d1d1 !important;
}

.sheet-radio {
  margin-bottom: -20px !important;
  margin-left: auto !important;
}

.scroller {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scroller::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 960px) {
  .left-panel {
    width: 100vw !important;
  }

  .right-panel {
    display: none !important;
  }
}

.back-button {
  color: #1b273e !important;
}
</style>