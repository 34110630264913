<template>
  <div class="wrapper">
    <div class="left-panel scroller">
      <v-row justify="center">
        <v-col cols="11" class="mt-5">
          <v-row class="ma-2 mt-5" v-if="$vuetify.breakpoint.smAndDown">
            <span class="wc-body-1 font-weight-bold">Paso {{ step }}.</span>
          </v-row>
          <v-row class="ma-2" v-if="$vuetify.breakpoint.smAndDown">
            <span class="wc-h5 font-weight-bold"
              >Valida la información de tu organización</span
            >
          </v-row>
          <v-row class="ma-2" v-if="$vuetify.breakpoint.smAndDown">
            <span class="wc-body-1">
              Estás a un paso de crear tu cuenta, confirma que las
              configuraciones que realizaste sean correctas.
            </span>
          </v-row>
          <v-row
            justify="center"
            v-if="$vuetify.breakpoint.smAndDown"
            class="ma-2"
          >
            <img
              src="@/assets/images/landing/signUp/image3.png"
              class="graph-img-movil"
            />
          </v-row>

          <v-row class="ma-2 mb-6">
            <span class="wc-h6 text-center">
              Recuento de Configuración
            </span>
          </v-row>

          <v-row class="ma-0 mb-0 ml-2">
            <span class="wc-body-1 mx-2 my-1 text-center font-weight-bold">
              Titular de la cuenta
            </span>
          </v-row>

          <v-row class="ma-0 ml-2">
            <span class="wc-body-1 mx-2 my-1 text-center">
              {{ accountData.name }} {{ accountData.lastName }}
            </span>
          </v-row>

          <v-row class="ma-2 mb-0 ml-2">
            <span class="wc-body-1 mx-2 my-1 text-center font-weight-bold">
              Correo Electrónico
            </span>
          </v-row>

          <v-row class="ma-0 ml-2">
            <span class="wc-body-1 mx-2 my-1 text-center">
              {{ accountData.email }}
            </span>
          </v-row>

          <v-row class="ma-2 mb-0 ml-2">
            <span class="wc-body-1 mx-2 my-1 text-center font-weight-bold">
              Módulos por activar
            </span>
          </v-row>

          <v-row class="ma-0 ml-2">
            <span class="wc-body-1 mx-2 my-1 text-center">
              {{
                accountSettings.role == "ORGANIZATION"
                  ? "Eventos"
                  : "Membresías y Eventos"
              }}
            </span>
          </v-row>

          <v-row class="ma-2 mb-0 ml-2">
            <span class="wc-body-1 mx-2 my-1 text-center font-weight-bold">
              La estructura de mi organización es
            </span>
          </v-row>

          <v-row class="ma-0 ml-2">
            <span class="wc-body-1 mx-2 my-1 text-center">
              {{
                accountSettings.hierarchyType == "ONE_LEVEL"
                  ? "Simple"
                  : "Nacional - Regional"
              }}
            </span>
          </v-row>

          <v-row class="ma-2 mb-0 ml-2">
            <span class="wc-body-1 mx-2 my-1 text-center font-weight-bold">
              Mi esquema de pagos es
            </span>
          </v-row>

          <v-row class="ma-0 ml-2">
            <span class="wc-body-1 mx-2 my-1 text-center">
              {{
                paymentSettings.paymentType == "LOCAL"
                  ? "Pago Local"
                  : paymentSettings.paymentType == "NATIONAL"
                  ? "Pago Central"
                  : "Pago Doble"
              }}
            </span>
          </v-row>

          <v-form ref="reviewForm">
            <v-row class="mx-4 mt-10 mb-0">
              <v-checkbox
                v-model="termsAndConditions"
                :rules="[
                  (v) => !!v || 'Debes aceptar los términos y condiciones',
                ]"
                color="#1b273e"
                hide-details="auto"
                dense
              >
                <template v-slot:label>
                  <span class="wc-body-2">
                    Acepto los
                    <router-link
                      class="wc-body-2 font-weight-bold"
                      to="/terminos-y-condiciones"
                      target="_blank"
                      >Términos y Condiciones
                    </router-link>
                    de uso de la plataforma.
                  </span>
                </template>
              </v-checkbox>
            </v-row>

            <v-row class="mx-3 mt-0 mb-4">
              <v-checkbox
                v-model="reviewChecked"
                :rules="[
                  (v) => !!v || 'Debes aceptar los términos y condiciones',
                ]"
                color="#1b273e"
                hide-details="auto"
                dense
              >
                <template v-slot:label>
                  <span class="wc-body-2">
                    He revisado la información de mi organización y es correcta.
                  </span>
                </template>
              </v-checkbox>
            </v-row>
          </v-form>

          <v-row justify="center" class="ma-4 mt-10">
            <v-btn
              rounded
              outlined
              color="white"
              class="back-button mx-2"
              @click="previousStep()"
            >
              REGRESAR
            </v-btn>
            <v-btn
              rounded
              color="#1b273e"
              class="white--text continue-button"
              :loading="loading"
              @click="nextStep()"
            >
              CREAR MI CUENTA
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="right-panel" v-if="!$vuetify.breakpoint.smAndDown">
      <v-row justify="center">
        <v-col cols="8">
          <v-row class="mt-10">
            <span class="wc-body-1 font-weight-bold">Paso {{ step }}.</span>
          </v-row>
          <v-row class="mt-4">
            <span class="wc-h5 font-weight-bold"
              >Valida la información de tu organización</span
            >
          </v-row>
          <v-row class="mt-4">
            <span class="wc-body-1">
              Estás a un paso de crear tu cuenta, confirma que las
              configuraciones que realizaste sean correctas.
            </span>
          </v-row>

          <v-row justify="center">
            <img
              src="@/assets/images/landing/signUp/image3.png"
              class="graph-img"
            />
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUp",

  props: {
    paymentSettings: {
      type: Object,
      required: true,
    },
    accountData: {
      type: Object,
      required: true,
    },
    accountSettings: {
      type: Object,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      termsAndConditions: false,
      reviewChecked: false,
    };
  },

  methods: {
    nextStep() {
      if (this.$refs.reviewForm.validate()) {
        this.$emit("nextStep");
      }
    },

    previousStep() {
      this.$emit("previousStep");
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh !important;
  width: 100vw !important;
  display: flex !important;
}

.left-panel {
  background-color: #fff !important;
  height: 100vh !important;
  width: 40vw !important;
  display: flex !important;
  flex-direction: column !important;
  z-index: 0 !important;
}

.right-panel {
  background-color: #ebebeb !important;
  height: 100vh !important;
  width: 60vw !important;
  display: flex !important;
  flex-direction: column !important;
  z-index: 1 !important;
}

.graph-img {
  height: 65vh !important;
  width: 65vh !important;
}

.graph-img-movil {
  width: 40vw !important;
  height: 40vw !important;
  min-width: 300px !important;
  min-height: 300px !important;
}

.orange-circle {
  position: absolute !important;
  height: 400px !important;
  width: 400px !important;
  left: -350px !important;
  bottom: 0px !important;
  opacity: 0.8 !important;
  z-index: -1 !important;
}

.blue-circle {
  position: absolute !important;
  height: 400px !important;
  width: 400px !important;
  left: 25vw !important;
  top: 50px !important;
  opacity: 0.8 !important;
  z-index: -1 !important;
}

.signUp-sheet {
  width: 90% !important;
  border: 1px solid #d1d1d1 !important;
}

.sheet-radio {
  margin-bottom: -20px !important;
  margin-left: auto !important;
}

.scroller {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scroller::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 960px) {
  .left-panel {
    width: 100vw !important;
  }

  .right-panel {
    display: none !important;
  }
}

.back-button {
  color: #1b273e !important;
}
</style>